<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-form-model
        ref="advZoneForm"
        :rules="rules"
        :model="info"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="广告位名称" prop="name">
          <a-input
            placeholder="请输入"
            v-model="info.name"
            :maxLength="50"
          ></a-input>
        </a-form-model-item>
        <div style="position: relative">
          <a-form-model-item label="广告位尺寸" prop="width">
            <a-input
              v-model.number="info.width"
              placeholder="请输入宽"
              style="width: 130px"
              :maxLength="4"
            >
              <span slot="suffix">像素</span>
            </a-input>
            <span style="margin: 0 8px">X</span>
          </a-form-model-item>
          <a-form-model-item
            prop="height"
            style="position: absolute; top: 0; left: 292px"
          >
            <a-input
              v-model.number="info.height"
              placeholder="请输入高"
              style="width: 130px"
              :maxLength="4"
            >
              <span slot="suffix">像素</span>
            </a-input>
          </a-form-model-item>
        </div>
        <a-form-model-item label="状态">
          <a-switch
            v-model="info.status"
            checked-children="启用"
            un-checked-children="禁用"
          />
        </a-form-model-item>
        <a-form-model-item
          :wrapper-col="{ span: 14, offset: 6 }"
          style="margin-top: 40px"
        >
          <pxkeji-btn name="保存" type="primary" @click="handleSubmit" />
          <pxkeji-btn
            name="取消"
            type="default"
            @click="cancelClick"
            class="cancelBtn"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
let _this;
export default {
  name: "advertzoneInfo",
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    let checkNum = (rule, value, callback) => {
      if (value) {
        var reg = /^[0-9]*$/;
        if (!reg.test(value)) {
          callback(new Error("请输入数字"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      rules: {
        name: [
          { required: true, message: "请输入广告位名称", trigger: "blur" },
        ],
        width: [{ validator: checkNum, trigger: "change" }],
        height: [{ validator: checkNum, trigger: "change" }],
      },
      info: {
        width: 0,
        height: 0,
        status: true,
      },
      isloading: false,
      loadingTip: "加载中...",
    };
  },
  mounted() {
    _this = this;
    if (_this.infos.id) {
      _this.getInfo();
    }
  },
  methods: {
    /**取消 */
    cancelClick() {
      _this.$emit("callbackMethod");
    },
    /**数据回显 */
    getInfo() {
      _this.isloading = true;
      _this.$api.adzone
        .getById(_this.infos.id)
        .then((res) => {
          if (res.errorCode == this.$msg.responseCode.success) {
            let width = 0;
            let height = 0;
            if (res.data.size) {
              let size = res.data.size.split("X");
              width = Number(size[0]);
              height = Number(size[1]);
            }
            _this.info = {
              ...res.data,
              width: width,
              height: height,
              status: _this.$commen.changeIntBoolean(res.data.status),
            };
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    handleSubmit() {
      _this.$refs.advZoneForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          let formData = {
            name: _this.info.name,
            size: _this.info.width
              ? `${_this.info.width}X${_this.info.height}`
              : "",
            status: _this.$commen.changeBooleanInt(_this.info.status),
          };
          if (_this.infos.id) {
            formData.id = _this.infos.id;
          }
          //新增
          _this.$api.adzone
            .saveOrUpdate(formData)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.cancelClick();
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style>
.anticon-plus-circle {
  margin-left: 10px !important;
  cursor: pointer;
}
.anticon-minus-circle {
  margin-left: 10px !important;
  cursor: pointer;
}
</style>